import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    // _progress;

    static init() {
        this._container = GetBy.id('Preloader');
        // this._progress = GetBy.class('__progress', this._container);
    }

    static update(__progress) {
        // gsap.killTweensOf(this._progress);
        // gsap.to(this._progress, {
        //     scaleX: __progress,
        //     ease: Power1.easeOut,
        //     duration: .1
        // });
    }

    static hide(__call) {
        // gsap.killTweensOf(this._progress);
        // gsap.to(this._progress, {
        //     scaleX: 1,
        //     ease: Power1.easeOut,
        //     duration: .2
        // });
        gsap.to(this._container, {
            duration: .5,
            opacity: 0,
            delay: .5,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
