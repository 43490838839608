import { gsap, Power2, Power3 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import Win from '../_app/cuchillo/windows/Window';

class ThankYou extends Win {
    constructor(__container) {
        super(__container, 'ThankYou');
    }

    // show(__d = 0) {
    //     // console.log('TEMP VALUE', Basics.tempValue);

    //     if (Basics.tempValue !== null) {
    //         this.directToggle(Basics.tempValue);
    //         Basics.tempValue = null;
    //     }

    //     localStorage.setItem('isBookOpen', 'true');
    //     super.show(__d);
    // }

    // show__effect(__d = 0) {
    //     KoraButtons.get("HeaderButtonBook").container.classList.add("__close");
    //     KoraButtons.get("HeaderButtonBook").state = KoraButton.STATE_MINI;
    //     KoraButtons.get("HeaderButtonBook").changeIcon("icon-cross", .2);

    //     gsap.set(this._title1, { y: '-100%', opacity: 0 });
    //     gsap.set(this._title2, { y: '100%', opacity: 0 });
    //     gsap.set(this.container, { opacity: 0 });

    //     let delay = .4;
    //     gsap.to(this.container, { opacity: 1, duration: .8, delay, ease: Power2.easeOut, onComplete: () => { this.afterShow() } });

    //     delay += .2;
    //     gsap.to(this._title1, {
    //         y: 0,
    //         duration: 1.1,
    //         ease: Power3.easeOut,
    //         force3D: true,
    //         delay
    //     });
    //     delay += .1;
    //     gsap.to(this._title1, {
    //         opacity: 1,
    //         duration: .1,
    //         ease: Power2.easeIn,
    //         force3D: true,
    //         delay
    //     });

    //     delay += .1;
    //     gsap.to(this._title2, {
    //         y: 0,
    //         duration: 1.1,
    //         ease: Power3.easeOut,
    //         force3D: true,
    //         delay
    //     });
    //     delay += .1;
    //     gsap.to(this._title2, {
    //         opacity: 1,
    //         duration: .1,
    //         ease: Power2.easeIn,
    //         force3D: true,
    //         delay
    //     });
    // }

    // afterShow() {
    //     this.enableScroll();
    //     super.afterShow();
    // }

    // hide__effect(__d = 0) {
    //     localStorage.setItem('isBookOpen', 'hide');
    //     KoraButtons.get("HeaderButtonBook").container.classList.remove("__close");
    //     KoraButtons.get("HeaderButtonBook").state = KoraButton.STATE_LARGE_ICON;
    //     KoraButtons.get("HeaderButtonBook").changeIcon();

    //     gsap.to(this._title1, {
    //         y: '-100%',
    //         duration: .6,
    //         ease: Power3.easeIn,
    //         force3D: true
    //     });
    //     /*gsap.to(this._title1, {
    //       opacity: 0,
    //       duration: .1,
    //       ease: Power2.easeIn,
    //       force3D: true,
    //       delay: .5
    //     });*/

    //     gsap.to(this._title2, {
    //         y: '100%',
    //         duration: .6,
    //         ease: Power3.easeIn,
    //         force3D: true,
    //     });


    //     gsap.to(this.container, { opacity: 0, duration: .4, delay: 0, ease: Power2.easeOut, onComplete: () => { this.afterHide(); } });
    // }

    dispose() {
        // if (this._form) this._form.dispose();
        // if (this.engine) this.engine.dispose();
        // this._btns.map(btn => { btn.removeEventListener(Basics.clickEvent, this._toggle) });
        super.dispose();
    }
}

export const WinThankYou = new ThankYou(GetBy.id('ThankYou'));