import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';
import { GetBy } from '../_app/cuchillo/core/Element';
import { WinThankYou } from '../windows/WinThankYou';

const URL = 'https://shop.cuchillo.tools/wp-json';

export default class ProductDetail extends Default {
  variants = [];
  thumbs = [];
  variantSelector;
  cta;

  constructor() {
    super();

    this.thumbs = [...GetBy.class('__thumb')];
    this.variantSelector = GetBy.class('__variants')[0];
    this.variants = [...GetBy.selector('option[data-variant]', this.variantSelector)];
    this.cta = GetBy.id('cta');

    this.addEvents();
    // this.getToken();
  }

  addEvents() {
    this._change = (e) => this.change(e);
    this.variantSelector.addEventListener('change', this._change);
  }

  removeEvents() {
    this.variantSelector.removeEventListener('change', this._change);
  }

  change(e) {
    const target = e.target;
    this.cta.href = target.value;

    for (let i = 0; i < this.thumbs.length; i++) {
      const thumb = this.thumbs[i];

      if (thumb.dataset.variant === target.options[target.selectedIndex].dataset.variant) {
        thumb.classList.remove('--hidden');
      } else {
        thumb.classList.add('--hidden');
      }
    }
  }

  //SHOW
  beforeShow() {
    super.beforeShow();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('variant') !== null && urlParams.get('thank-you') !== null) {
      WinThankYou.show();

      const variant = urlParams.get('variant');
      let prodId;
      let index = 0;

      for (let i = 0; i < this.variants.length; i++) {
        const vari = this.variants[i];
        if (vari.dataset.variant === variant) {
          index = i;
          prodId = parseInt(vari.dataset.id);
        }
      }

      fetch(`/api/get-product?prodId=${prodId}`)
        .then(response => response.json())
        .then(product => {
          if (product.error) {
            console.error('Error fetching product:', product.error);
            return;
          }

          const newStock = parseInt(product.acf.variants[index].stock);
          product.acf.variants[index].stock = newStock - 1;

          this.pushProduct(prodId, product);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  pushProduct(id, product) {
    fetch('/.netlify/functions/push-product', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ id, product })
    })
      .then(response => response.json())
      .then(updatedProduct => {
        const variants = updatedProduct.acf.variants;
        for (let i = 0; i < this.variants.length; i++) {
          if (variants[i].stock === null) this.variants[i].setAttribute('disabled', 'disabled');
        }

        this.triggerDeploy();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  triggerDeploy() {
    fetch('/.netlify/functions/trigger-deploy', { method: 'POST' })
      .then(response => response.json())
      .then(data => {
        window.history.replaceState({}, document.title, '/');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  afterHide() {
    super.afterHide();

    this.removeEvents();
  }
}

ControllerPage._addPage('product-detail', ProductDetail);
